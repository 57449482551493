//localhost
const url = 'https://tghome.inqbaytor.me/login';
const durl = 'https://tghome.inqbaytor.me/dashboard';

//pp
// const url = 'https://tguihomepp.enabledbeings.com/login';
// const durl = 'https://tguihomepp.enabledbeings.com/dashboard';

//test
// const url = 'https://tguihome.enabledbeings.com/login';
// const durl = 'https://tguihome.enabledbeings.com/dashboard';

//live
// const url = 'https://ticketgadget.com/login';
// const durl = 'https://ticketgadget.com/dashboard';

//paymentTest
// const url = 'https://paymentupdateuilogin.enabledbeings.com/login';
// const durl = 'https://paymentupdateuilogin.enabledbeings.com/dashboard';


export const redirectUrls = {
    redirectLogin : `${url}`,
    redirectDashboard:`${durl}`,
}