<div>
    <!-- start page title -->
    <!-- <app-page-title title="Dashboard"></app-page-title> -->
    <div class="box shine"></div>
    <div class="box shine"></div>
    <div class="box shine"></div>
    <div class="row">
        <div class="col-md-6 col-xl-3">
            <div class="lines shine"></div>       
        </div>
        <div class="col-md-6 col-xl-3">
            <div class="lines shine"></div>       
        </div>
        <div class="col-md-6 col-xl-3">
            <div class="lines shine"></div>       
        </div>
        <div class="col-md-6 col-xl-3">
            <div class="lines shine"></div>       
        </div>
    </div>
</div>
