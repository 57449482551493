import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private httpClient:HttpClient,
    private spinner: NgxSpinnerService) 
    {
      // sessionStorage.setItem('access-token','eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjM2YTg5NjE3Yjg5MjdkMjZmNTIzNGYxMTA1M2NlODViM2FmZGM3NDU0NzU2NWU4ZWNkMDhhNDM0M2RiN2M4YTJmYWY3YTMyZDRmYjA5NDVhIn0.eyJhdWQiOiIxIiwianRpIjoiMzZhODk2MTdiODkyN2QyNmY1MjM0ZjExMDUzY2U4NWIzYWZkYzc0NTQ3NTY1ZThlY2QwOGE0MzQzZGI3YzhhMmZhZjdhMzJkNGZiMDk0NWEiLCJpYXQiOjE2MDYxOTY5NTUsIm5iZiI6MTYwNjE5Njk1NSwiZXhwIjoxNjM3NzMyOTU1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.Rqj_Ss96LVSZpiWl9C79cZ4KW2rwSiIqnXlZzbBZq4a3J7o3e6m7ANzyazbI3XpkAIYseNfYsfVbH3aZ13uhndnWOVqZkN5Y-hOwg75XY9SaHfGCZQ7HtXz9tzl5zLbEwiv-n3EtnT2eqD8KwiaE1UqwdqFuC8RXSd8JN8mHvfZg0u2k4W76jTEA3xuIiPWYTmtIJsLsFlumcVitt6Ycgd7k5bdtNiQZ8jO3PMVFnOzzqAeH6NnyqjF8v6VVP0pDG1j6USy261nhNw_mUy75ERcw3wqNQ-ptV9yA3cUt9RAImjcx2GERl1sSoLG-Qt4bYih2RRIfxAQzMYkZCEbjU00aV8NPcYgNOX5H6N8q5n3hRUYt7bppX_UgXu-BpCBLRUDdc4swTQwh7qB8ZbIL6QZV04sAewkYafsAs-JrPL0rAnkSXHt74VqKqYZdS7QueuQYB4ps-i5NsRG4OThOcUZSA8e0lBoO04B0Pjsm3flSY-nyQjNs5THH0FgKaww-epVSrKEBeRnQycTl7yKSitqUXs54o0RfiVuyQWZQYxZ4ffCZV63JSmHhv7T2ozueyNkZCHu96f38fQWZpsVyTDsqxQs3ppx0luVYsnLBXoioC0_EGAaz3chGJCGvviBhfpGBcB7FWxqpLLJ56cCCjvoQAOIS7P93FpTjk3kXX9w');
   }

  // constructor(private httpClient:HttpClient) { 
  //   sessionStorage.setItem('access-token','eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjM2YTg5NjE3Yjg5MjdkMjZmNTIzNGYxMTA1M2NlODViM2FmZGM3NDU0NzU2NWU4ZWNkMDhhNDM0M2RiN2M4YTJmYWY3YTMyZDRmYjA5NDVhIn0.eyJhdWQiOiIxIiwianRpIjoiMzZhODk2MTdiODkyN2QyNmY1MjM0ZjExMDUzY2U4NWIzYWZkYzc0NTQ3NTY1ZThlY2QwOGE0MzQzZGI3YzhhMmZhZjdhMzJkNGZiMDk0NWEiLCJpYXQiOjE2MDYxOTY5NTUsIm5iZiI6MTYwNjE5Njk1NSwiZXhwIjoxNjM3NzMyOTU1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.Rqj_Ss96LVSZpiWl9C79cZ4KW2rwSiIqnXlZzbBZq4a3J7o3e6m7ANzyazbI3XpkAIYseNfYsfVbH3aZ13uhndnWOVqZkN5Y-hOwg75XY9SaHfGCZQ7HtXz9tzl5zLbEwiv-n3EtnT2eqD8KwiaE1UqwdqFuC8RXSd8JN8mHvfZg0u2k4W76jTEA3xuIiPWYTmtIJsLsFlumcVitt6Ycgd7k5bdtNiQZ8jO3PMVFnOzzqAeH6NnyqjF8v6VVP0pDG1j6USy261nhNw_mUy75ERcw3wqNQ-ptV9yA3cUt9RAImjcx2GERl1sSoLG-Qt4bYih2RRIfxAQzMYkZCEbjU00aV8NPcYgNOX5H6N8q5n3hRUYt7bppX_UgXu-BpCBLRUDdc4swTQwh7qB8ZbIL6QZV04sAewkYafsAs-JrPL0rAnkSXHt74VqKqYZdS7QueuQYB4ps-i5NsRG4OThOcUZSA8e0lBoO04B0Pjsm3flSY-nyQjNs5THH0FgKaww-epVSrKEBeRnQycTl7yKSitqUXs54o0RfiVuyQWZQYxZ4ffCZV63JSmHhv7T2ozueyNkZCHu96f38fQWZpsVyTDsqxQs3ppx0luVYsnLBXoioC0_EGAaz3chGJCGvviBhfpGBcB7FWxqpLLJ56cCCjvoQAOIS7P93FpTjk3kXX9w');
  // }

  public apiAuthPost(url,data):any{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${localStorage.getItem('access-token')}`)
        .set('Content-Type',`application/json`)
        .set('Access-Control-Allow-Origin',`*`)
    }
    // if(data != null){
      return this.httpClient.post(url,JSON.stringify(data),header);
    // }else{
    //   return this.httpClient.post(url,header);
    // }
  }

  public apiAuthGet(url):any{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${localStorage.getItem('access-token')}`)
        .set('Content-Type',`application/json`)
        .set('Access-Control-Allow-Origin',`*`)
    }
    return this.httpClient.get(url,header);
  }

  public apiGet(url):any{
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type',`application/json`)
        .set('Access-Control-Allow-Origin',`*`)
    }
    return this.httpClient.get(url,header);
  }

  public apiPost(data,url):Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type',`application/json`)
        .set('Access-Control-Allow-Origin',`*`)
    }
    return this.httpClient.post(url,JSON.stringify(data),header);
  }  

  public apiPostFormData(data,url):Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${localStorage.getItem('access-token')}`)
        .set('Access-Control-Allow-Origin',`*`)
        .set('Accept',`application/json`)
      }
    return this.httpClient.post(url,data,header);
  }  
}
