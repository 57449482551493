// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version:'1.1.61',

  //*Ticket Gadget Api Endpoints
  // base_url :"http://localhost:8000/PRE-PRODUCTION/ticketgadget-api/localapi.ticketgadget.com/public/api/",
  base_url :"https://ticketgadgetlk-api.inqbaytor.com.au/api/",
  reissue_url : "https://reissue.inqbaytor.co/api/",
  donwloadTicket : "https://pdf.inqbaytor.com/#//booking",
  gds : 'https://advancesearch.inqbaytor.co/#/NDCSimulator',
  donwloadTicket1 : "https://pdf.inqbaytor.com/#/",
  
  //*CrazyJets Api Endpoints
  base_url_cjw :"https://cjwapi.inqbaytor.co/api/",

  //*FlyDubai Api Endpoints
  base_url_fly_dubai:"https://fzapi.inqbaytor.com/api/",

  //*Jazeera Api Endpoints
  base_url_jazeera:"https://j9api.inqbaytor.co/api/",

  //*Document upload Api Endpoints
  base_url_documents:"https://document.inqbaytor.co/api/",

  //*Ticket image Api Endpoints
  base_url_ticket_image:"https://ticketimage.inqbaytor.co/api/",

  //*event image Api Endpoints
  base_url_event:"https://events.inqbaytor.co/api/",

  //*NDC Api Endpoints
  base_url_ndc : "https://wyndc.inqbaytor.com/api/",

  //*Payment Link new
  base_url_credit_card : "https://paymentlink.inqbaytor.co/api/",

  //*FIts Air Api Endpoints
  base_url_fits_air : "https://8d.inqbaytor.co/api/",

  //*Fits Air Api Endpoints TG
  base_url_fits_air_tg : "https://8d-pbs.inqbaytor.co/api/",

  //*Fly dubai api enpoint
  base_url_fz : "https://fz-pbs.inqbaytor.co/api/",

  defaultauth: 'fackbackend',
  firebase: {
    apiKey: "AIzaSyCPYIQ6MvKfpjggwhHJqDH3XE2yvQJK2ng",
    authDomain: "tgapp-aa244.firebaseapp.com",
    databaseURL: "https://tgapp-aa244.firebaseio.com",
    projectId: "tgapp-aa244",
    storageBucket: "tgapp-aa244.appspot.com",
    messagingSenderId: "204974528182",
    appId: "1:204974528182:web:869d0f1c2b8a9e08937817",
    measurementId: "G-FS07BQGF53"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
