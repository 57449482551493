import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';   
import { GlobalConstants, isOverdraftAvailable } from '../helpers/globals';

@Injectable({providedIn: 'root'})
export class GlobalService {

  constructor(private myGlobals:GlobalConstants) {
    console.log(myGlobals.isOverdraftAvailable);
  }

 itemValue = new BehaviorSubject(this.theItem);
 overdraftStatus = new BehaviorSubject(this.overdraftStatusValue);
 reissueStatus = new BehaviorSubject(this.reissueStatusValue);
 refundCount = new BehaviorSubject(this.refundCountValue);
 reIssueCount = new BehaviorSubject(this.reissueCountValue);
 accounts = new BehaviorSubject(this.inqbaytorPayAccount);
 badgeStatus = new BehaviorSubject(this.badgeStatusValue);
 admStatus = new BehaviorSubject(this.admAccountValue);
 ticketPassStatus = new BehaviorSubject(this.TicketPassStatusValue);

  set theItem(value) {
    this.itemValue.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('notifyCount', value);
  }
  get theItem() {
    return localStorage.getItem('notifyCount');
  }

////////////////////////

  set badgeStatusValue(value) {
    this.badgeStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isBadgesAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get badgeStatusValue() {
    return localStorage.getItem('isBadgesAvailable');
  }


/////////////////////

  set overdraftStatusValue(value) {
    this.overdraftStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isOverdraftAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get overdraftStatusValue() {
    return localStorage.getItem('isOverdraftAvailable');
  }
/////////////////////
  set reissueStatusValue(value) {
    this.reissueStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isReissueAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get reissueStatusValue() {
    return localStorage.getItem('isReissueAvailable');
  }

/////////////////////
  set refundCountValue(value) {
    this.refundCount.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('refundCount', value);
  }

  get refundCountValue() {
    return localStorage.getItem('refundCount');
  }

/////////////////////
  set reissueCountValue(value) {
    this.reIssueCount.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('reissueCount', value);
  }

  get reissueCountValue() {
    return localStorage.getItem('reissueCount');
  }

/////////////////////
  set inqbaytorPayAccount(value) {
    this.accounts.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('inqbaytorPayAccount', value);
  }

  get inqbaytorPayAccount() {
    return localStorage.getItem('inqbaytorPayAccount');
  }

/////////////////////
  set admAccountValue(value) {
    this.accounts.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('admAccount', value);
  }

  get admAccountValue() {
    return localStorage.getItem('admAccount');
  }

  //////////////////////
  set TicketPassStatusValue(value) {
    this.ticketPassStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isTicketPassAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get TicketPassStatusValue() {
    return localStorage.getItem('isTicketPassAvailable');
  }  

}
