<div>
    <div class="card">
        <div class="card-body">
            <!-- <div><div class="lines shine"></div></div>
            <div><div class="lines shine"></div></div>
            <div><div class="lines shine"></div></div>
            <div><div class="lines shine"></div></div>
            <div><div class="lines shine"></div></div>
            <div><div class="lines shine"></div></div>
            <div><div class="lines shine"></div></div>
            <div><div class="lines shine"></div></div> -->
            <div class="table-responsive">
                <table id="basic-datatable" class="table table-bordered dt-responsive nowrap w-100 datatables">
                    <tbody>
                        <tr class="font-size-12" *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]">
                            <td>
                                <div class="lines shine"></div>
                            </td>
                            <td>
                               <div class="lines shine"></div>
                            </td>
                            <td>
                                <div class="lines shine"></div>
                            </td>
                            <td>
                                <div class="lines shine"></div>
                            </td>
                            <td>
                                <div class="lines shine"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
