import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { number } from 'echarts';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { mergeMapTo } from 'rxjs/operators';
import { apiEndPoints } from './core/helpers/api-endpoints';
import { ApiService } from './core/services/api.service';
import { GlobalService } from './core/services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  subscription:Subscription[] = [];

  constructor(
    private afMessaging: AngularFireMessaging,
    private apiService:ApiService,
    private toastr: ToastrService,
    private router: Router, 
    private globalSrv: GlobalService
    ) { 
    }

  ngOnInit() {
    //  document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");  
    // localStorage.setItem('access-token','eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjQyNWNiYTIyYTNmZmQ1ZDkzZDYyZmRkMmI2ZjQyNWFkNzVlNDcwYTMwMGFlNmQ2OGRlNGRmYTIxNTVmM2Q3MDZiYTczOTJiOWVlZjk1YjUyIn0.eyJhdWQiOiIxIiwianRpIjoiNDI1Y2JhMjJhM2ZmZDVkOTNkNjJmZGQyYjZmNDI1YWQ3NWU0NzBhMzAwYWU2ZDY4ZGU0ZGZhMjE1NWYzZDcwNmJhNzM5MmI5ZWVmOTViNTIiLCJpYXQiOjE2NDMwOTQyNjMsIm5iZiI6MTY0MzA5NDI2MywiZXhwIjoxNjc0NjMwMjYzLCJzdWIiOiIxMDAwMzciLCJzY29wZXMiOltdfQ.nnJPffrSux_El0R9HImn8eGPHaUxKZMaNe0HAfnrQ6Crmo7oH0dHpuQGkaLCwr-C-VBrxMe6yfXDvkzjXYCoi1xuwWZWWDiKzpPb5KTvbc_AephF7ryVw1bJZr04k23d4e9o7tgXNl211bjjJXdQXQO-kFRJ2FObZq9MXkx-fPOTPoRsEY_mQUJljW92mv-tbYGdjigUbRjAmlGB7Q2oNoZCgiBFBIx71pghr1teoSjomkccsnjT5af1ao3KTj5EUUD1KaatUtLzTCAdCFE5B2DWDq7AHJ14XbF0y0Oj3XzboK7CrIe0sy8bMmtALiqLMS40fKHw1-63wJ47u79UZmiFNa4C5r6MoTG8CTpop1DxwFoq1rZOoup6BQQQiTGz6bEfcIJl6t1_D71hts5Egpbax38gmGWnBJPhT4fxfkJeVGuIQBYrOYRBxIzgM8BKwx2L8F9C3LDbGk0K_jYhTmS62tm8vKtV7-1PPt_ge9nTWgkdun4l6q68v8XGu-r8Cm_yt3T20sYMaCXlSI_CXb1YLQbWCPyEaGfFI9MorQaVqQTtAodx-CeuY2AiBx5kz9LVAewIdmEDVef42tqHxUnS2bCOCLCmPOW5bPKnVKshkDM_FxkC6yz_8IuNhEWd6rj81p2k9fXehfzFkn3DvkchzCqmUe0CZ6p358sdY44')
    if(localStorage.getItem("fcm") == null){
      this.requestPermission();
    }
     this.listen();  
  }
  
  requestPermission() {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe(
        (token) => {
           var data = {
            "firebase_token":token
           }
           setTimeout(()=>{
            this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.fcmSave,data).subscribe(res => {
              localStorage.setItem("fcm",token);
              // this.spinner.hide();
              // if(res['code'] == 1){
              //   this.toastr.success('', res.message);
              // }
            },error=>{
              // alert("FAILED")
            }))  
           },2000)
          
          },
        (error) => { console.error(error); },  
      );
  }

  listen() {
    this.afMessaging.messages
      .subscribe((message) => {
        const toastr = this.toastr.info(message['notification']['title'],message['notification']['body']);
        toastr.onTap.subscribe(action => {
          this.router.navigateByUrl('/dashboard');
        });
        var count = Number(localStorage.getItem('notifyCount'));
        this.globalSrv.theItem = message['data']['unreadNotificationsCount'];
        this.globalSrv.inqbaytorPayAccount = message['data']['inqbaytorPayAccountBalances'];
      } 
    );
  }


 
}
