import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { initFirebaseBackend } from './authUtils';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
// import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { DecimalPipe } from '@angular/common';
import { PopoverModule } from 'ngx-smart-popover';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { CardModule } from 'ngx-card';
import {NgxPaginationModule} from 'ngx-pagination';
import { CountdownModule } from 'ngx-countdown';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { NgxSmartBannerModule } from '@netcreaties/ngx-smart-banner';
import { GlobalConstants } from './core/helpers/globals';
import { NgxLazyLoadModule } from 'ngx-lazy-load';
import { NgxLoadingXConfig, NgxLoadingXModule, POSITION, SPINNER } from 'ngx-loading-x';
import { Line } from 'chartist';
import { WebcamModule } from 'ngx-webcam';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { LogoutComponent } from './logout/logout.component';
import { VersionComponent } from './version/version.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// if (environment.defaultauth === 'firebase') {
//   initFirebaseBackend(environment.firebaseConfig);
// } else {
  // tslint:disable-next-line: no-unused-expression
  // FakeBackendInterceptor;
// }

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const ngxLoadingXConfig: NgxLoadingXConfig = {
  show: false,
  bgBlur:1,
  bgOpacity: 2,
  bgLogoUrl: '',
  bgColor: '',
  // bgLogoUrlPosition: POSITION.topLeft,
  spinnerType: SPINNER.xBallSpin,
  spinnerSize: 30,
  spinnerColor: '#DD6E0F',
  spinnerPosition: POSITION.centerCenter,
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    LogoutComponent,
    VersionComponent,   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut:60000,
      toastClass:"ngx-toastr",
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      newestOnTop:true,
    }),
    PopoverModule,
    NgxDaterangepickerMd.forRoot(),
    NgxIntlTelInputModule,
    BsDropdownModule.forRoot(),
    // CardModule,
    NgxPaginationModule,
    CountdownModule,
    LoadingBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    NgxLazyLoadModule,
    NgxLoadingXModule.forRoot(ngxLoadingXConfig),
    WebcamModule,
    NgxTypeaheadModule 
  ],
  bootstrap: [AppComponent],
  providers: [
    DecimalPipe,
    GlobalConstants,
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
  ],

})
export class AppModule { }
