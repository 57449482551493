import { Component, OnInit, ViewChild } from '@angular/core';
import { transactions, lineColumAreaChart, revenueColumnChart, customerRadialBarChart, orderRadialBarChart, growthColumnChart} from './data';

import { ChartType } from './dashboard.model';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { apiEndPoints } from 'src/app/core/helpers/api-endpoints';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe, NgLocalization, PlatformLocation } from '@angular/common';
import { ChartComponent } from 'ng-apexcharts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountdownComponent } from 'ngx-countdown';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { redirectUrls } from 'src/app/core/helpers/redirect-url';
import * as myGlobals from 'globals'; //<==== this one (**Updated**)
import { GlobalService } from 'src/app/core/services/global.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { interval, pipe } from 'rxjs'; 
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})

export class DefaultComponent implements OnInit {

  lineColumAreaChart: ChartType;
  revenueColumnChart: ChartType;
  orderRadialBarChart: ChartType;
  customerRadialBarChart: ChartType;
  growthColumnChart: ChartType;
  transactions;
  breadCrumbItems: Array<{}>;

  subscription:Subscription[] = [];
  dashboardData;
  transactionData = [];
  balance;
  galelioPresentage;
  amadeusPresentage;
  sabrePresentage;
  voidPresenatge;
  loading = true;
  selectedSortValue = "Last 7 Days";
  ChartData = false;
  user;
  optForm:FormGroup;
  isSmsExpired = false;
  smsSubmit = false;
  token;
  payId;
  date;
  redirectUrl;
  isConsolidator;
  topDeals = [];
  images = [];
  arrows = false;
  infoBanners = [];
  overviewData;
  headerData = [];
  loading1 = true;
  loading2 = true;
  loading3 = true;
  loading4 = true;
  loading5 = true;
  section1data;
  section3data;
  section5data = [];
  primaryAccountData;
  secondaryAccountData;
  admAccountData;
  isAdm = false;
  admBalance;
  primaryBalance;
  secondaryBalance;
  inqbaytorPayAccounts = [];
  isShowUpdate = false;

  spinner1 = 'sp1';

  smstimeOutConfig = {
    'leftTime': 120, 'format': 'mm:ss','demand':false,
  }

  constructor(
    private apiService:ApiService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route:ActivatedRoute,
    private locationStrategy: LocationStrategy,
    private router:Router,
    private globalSrv: GlobalService,
    private loadingBar: LoadingBarService
    ) { 
    // this.checkinUserActive();
    this.transactionData = [];
    this.section5data = [];
    this.optForm = this.formBuilder.group({
      smsCode:['',[Validators.required]],
    });
    if(localStorage.getItem('access-token') == null || localStorage.getItem('access-token') != undefined){
      this.route.queryParams.subscribe(
        params => {
          this.token = params.token
        }
      );
      const qParams: Params = {};
      this.router.navigate([], {
          relativeTo: this.route,
          queryParams: qParams,
          queryParamsHandling: ''
      });
    }
    globalSrv.accounts.subscribe((nextValue) => {
      this.inqbaytorPayAccounts = JSON.parse(nextValue);
      if(this.inqbaytorPayAccounts != null && this.inqbaytorPayAccounts.length){
        this.inqbaytorPayAccounts.forEach(element => {
          if(element.primary == 1){
            this.primaryAccountData = element;
            this.primaryBalance = parseFloat(element.closingBalance);
          }
          if(element.primary == 0){
            this.secondaryAccountData = element;
            this.secondaryBalance = parseFloat(element.closingBalance);
          }
        });
      }
      // this.inq = nextValue;
    })
  }

  @ViewChild("chart", { static: false }) chart: ChartComponent;
  @ViewChild('otpmodel', { static: false }) private otpmodel;
  @ViewChild("otp", { static: false }) private countdown: CountdownComponent;

  ngOnInit(): void {
    /**
     * Fetches the data
     */
    this.section1data = JSON.parse(localStorage.getItem("section1Data"));
    if(this.section1data != null){
      if(!this.section1data.hasOwnProperty("inqbaytorPayAccounts")){
        localStorage.removeItem('section1Data');
        localStorage.removeItem('section2Data');
        localStorage.removeItem('section3Data');
        localStorage.removeItem('section4Data');
        localStorage.removeItem('section5Data');
      }
    }
    this.fetchData();
    // this.spinner.show("sp6",{
    //   type: "line-scale-party",
    //   size: "small",
    // })
    // this.GetTransactionOverviewData();
    // this.breadCrumbItems = [{ label: 'Minible' }, { label: 'Dashboard', active: true }];
    // this.checkLogin();
    this.loadingBar.start();
    this.redirectUrl = redirectUrls.redirectDashboard;
  }



  
  /**
    * Send api request background every 10 minute
    */  
  // checkingTokenOnInterval(){
  //   const intervalTime = 10*1000;
  //   interval(intervalTime).pipe(switchMap(() => this.refreshToken())).subscribe(()=>{
  //               //do something 
  //   });
  // } 

  /**
   * 
   */
  loaded(section) {
    switch(section){
      case 1:      
        this.checkLogin();
        break;
      case 2:
        this.GetTransactionOverviewData();
        break;
      case 3:
        this.getSectionThreeData();
        break;
      case 4:
        this.getSectionFourData();
        break;
      case 5:
        this.getSectionFiveData();
        break;
    }
  }

  /**
   * Get Section 1 data
   */
  getSectionOneData(){
    this.spinner.show("sp1",{
      size: "medium",
      type:"ball-scale-multiple",
      bdColor:"rgba(239, 234, 234, 0.1)",
      color:"#DD6E0F"
    });
    this.user = JSON.parse(localStorage.getItem('user'));
    this.checkUserStatus();
    this.loading1 = true;
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.sectionOneData,{}).subscribe(res => {
      if(res.code == 1){
        this.section1data = res.data;
        localStorage.setItem("section1Data",JSON.stringify(this.section1data));
        this.isConsolidator = this.section1data.isConsolidator;
        localStorage.setItem('isConsolidator',this.isConsolidator);
        let inqPayIdHexValue = parseInt(this.section1data.inqPayId).toString(16);
        localStorage.setItem('jazeeraActiveStatus',this.section1data.jazeeraActiveStatus);
        localStorage.setItem('dubaiActiveStatus',this.section1data.isFlyDubaiEnabled);
        localStorage.setItem('ndcActiveStatus',this.section1data.ndcActiveStatus);
        localStorage.setItem('fitsAirActiveStatus',this.section1data.fitsAirActiveStatus);
        this.payId = "T" + inqPayIdHexValue.toUpperCase();
        this.balance = parseFloat(this.section1data.inqPayBalance);
        this.globalSrv.theItem = this.section1data.unreadNotificationsCount;
        this.globalSrv.refundCountValue = this.section1data.userApprovalPendingRefundRequestsCount;
        this.globalSrv.reissueCountValue = this.section1data.userApprovalPendingReissueQuoteRequestsCount;
        this.infoBanners = this.section1data.infoBanners;  
        
        /////Multiple account scenario
        if(this.section1data.inqbaytorPayAccounts.length > 1){
          this.section1data.inqbaytorPayAccounts.forEach(element => {
            if(element.primary == 1){
              this.primaryAccountData = element;
              this.primaryBalance = parseFloat(element.closingBalance);
            }
            if(element.account_type == "Pre-paid"){
              this.secondaryAccountData = element;
              this.secondaryBalance = parseFloat(element.closingBalance);
            }
            if(element.account_type == "ADM"){
              this.admAccountData = element;
              this.admBalance =  parseFloat(element.closingBalance);
              if(parseFloat(element.closingBalance) != 0){
                this.isAdm = true;
              }
              if(parseFloat(element.closingBalance) < 0){
                this.globalSrv.admAccountValue  = '1';
              }else{
                this.globalSrv.admAccountValue  = '0';
              }
            }
          });
        }       
        this.loading1 = false;   
        this.spinner.hide("sp1")   
      }
    },error=>{
    }))     
  }

  /**
   * get Section 3 Data
   */
   getSectionThreeData(){
    this.loading3 = true;
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.sectionThreeData,{}).subscribe(res => {
      if(res.code == 1){
        this.section3data = res.data; 
        localStorage.setItem('section3Data',JSON.stringify(this.section3data));
        let galeliovalue = (this.section3data.galileoTicketsCount * 100) /  this.section3data.totalAirTicketsCount;
        let amadeusvalue = (this.section3data.amadeusTicketsCount * 100) /  this.section3data.totalAirTicketsCount;
        let sabrevalue = (this.section3data.sabreTicketsCount * 100) /  this.section3data.totalAirTicketsCount;
        let voidvalue = (this.section3data.totalVoidedTicketsCount * 100) /  this.section3data.totalAirTicketsCount;
  
        this.galelioPresentage = galeliovalue+'%';
        this.amadeusPresentage = amadeusvalue+'%';
        this.sabrePresentage = sabrevalue+'%';
        this.voidPresenatge = voidvalue+'%';
        this.bindDataToChart(this.section3data.graphData);

        this.topDeals = this.section3data.topDeals;
        if(this.topDeals.length > 0){
          this.arrows = true;
        }else{
          this.arrows = false;
        }
        this.topDeals.forEach((e)=>{
          var data = {
            path:e.image_url
          }
          this.images.push(data);
        });
        this.loading3 = false;      
      }
    },error=>{
    }))     
  }  

 /**
   * Get section 4 data
   */
  getSectionFourData(){
    this.loading4 = true;
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.sectionFourData,{}).subscribe(res => {
      if(res.code == 1){
        this.transactionData = [];
        this.transactionData = res.data.latestTransactions.filter(x=> (x.paid_amount != "" || x.air_ticket_number != null));
        localStorage.setItem('section4Data',JSON.stringify(this.transactionData));
      }
      this.loading4 = false;
    },error=>{
    }))    
  }

   /**
   * Get section 5 data
   */
    getSectionFiveData(){
      this.loading5 = true;
      this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.sectionFiveData,{}).subscribe(res => {
        if(res.code == 1){
          this.section5data = [];
          this.section5data = res.data.latestTickets;
          localStorage.setItem('section5Data',JSON.stringify(this.section5data));
        }
        this.loading5 = false;
      },error=>{
      }))    
    }


  /**
   * Get transaction overview data
   */
  GetTransactionOverviewData(){
    this.loading2 = true;
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.transactionOverviewv2,{}).subscribe(res => {
      if(res.code == 1){
        localStorage.setItem('section2Data',JSON.stringify(res))
        // this.headerData = res.header_data;
        // this.overviewData = res.overview_data;
        this.overviewData = res;
      }
      this.loading2 = false;
    },error=>{
    }))    
  }

  checkLogin(){
    var user = localStorage.getItem('user');
    if(user === null || user == 'undefined'){
      this.subscription.push(this.apiService.apiAuthGet(apiEndPoints.getUserData).subscribe(res => {
        localStorage.setItem("user",JSON.stringify(res));
        // this.fetchDashboardData();
        this.getSectionOneData();
      },error=>{
      }))
    }else{
      // this.fetchDashboardData();
      this.getSectionOneData();
    }
  };


  ngOnDestroy(){
    this.subscription.forEach(list=>{
      list.unsubscribe();
    })
  }

    /**
   * Returns the type validation form
   */
     get smstype() {
      return this.optForm.controls;
    }

  /**
   * Test Login function
   */
    // login(){
    //   this.spinner.show();
    //   var data = {
    //     "email":"yashas@crazyjets.com",
    //     "password":"9z8ErHhopS"
    //   }
    //   this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.login,data).subscribe(res => {
    //     // var response = JSON.parse(res);
    //     this.spinner.hide();
    //     sessionStorage.setItem("user",JSON.stringify(res.user));
    //     this.fetchDashboardData();
    //     // alert("success")
    //   },error=>{
    //     // alert("FAILED")
    //   }))
    // }

  /**
   * dashboard promo data
   */  
  getDashboardPromo(){
    this.loading = true;
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.promoData,{}).subscribe(res => {
      if(res.code == 1){
        this.topDeals = res.data.topDeals;
        if(this.topDeals.length > 0){
          this.arrows = true;
        }else{
          this.arrows = false;
        }
        this.topDeals.forEach((e)=>{
          var data = {
            path:e.image_url
          }
          this.images.push(data);
        });
      }
      this.checkUserStatus();
      this.fetchDashboardData();
    },error=>{
    }))
  }

  /**
   * This function will check user status
   */
  checkUserStatus(){
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.userStatus,{}).subscribe(res => {
      if(res.code == 1){
        this.globalSrv.overdraftStatusValue = res.data.overdraftRequestStatus;
        this.globalSrv.reissueStatusValue = res.data.reissueRequestStatus;
        this.globalSrv.badgeStatusValue = res.data.badgesRequestStatus;
        this.globalSrv.TicketPassStatusValue = res.data.ticketPassStatus;
      }
    },error=>{
    }))    
  }


  /**
   * Fetches latest dashboad data
   */
   fetchDashboardData(){
    this.user = JSON.parse(localStorage.getItem('user'))
    // this.spinner.show();
    // this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.transactionDetails,{}).subscribe(res => {
    //   // this.spinner.hide();
    //   if(res.code == 1){
    //     this.transactionData = res.data;
    //   }
    //   // alert("success")
    // },error=>{
    //   // alert("FAILED")
    // }))
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.details,{}).subscribe(res => {
      this.date = new Date()
      this.spinner.hide();
      this.dashboardData = res;
      this.isConsolidator = this.dashboardData.isConsolidator;
      localStorage.setItem('isConsolidator',this.isConsolidator);
      localStorage.setItem('jazeeraActiveStatus',this.dashboardData.jazeeraActiveStatus);
      let inqPayIdHexValue = parseInt(this.dashboardData.inqPayId).toString(16);
      let tempTransaction;
      this.transactionData = res.latestTransactions.filter(x=> (x.paid_amount != "" || x.air_ticket_number != null));
      // this.transactionData = res.latestTransactions;
      this.payId = "T" + inqPayIdHexValue.toUpperCase();
      this.balance = parseFloat(this.dashboardData.inqPayBalance); 
      let galeliovalue = (this.dashboardData.galileoTicketCount * 100) /  this.dashboardData.totalAirTickeCount;
      let amadeusvalue = (this.dashboardData.amadeusTicketCount * 100) /  this.dashboardData.totalAirTickeCount;
      let sabrevalue = (this.dashboardData.sabreTicketCount * 100) /  this.dashboardData.totalAirTickeCount;
      let voidvalue = (this.dashboardData.voidTicketCount * 100) /  this.dashboardData.totalAirTickeCount;

      this.galelioPresentage = galeliovalue+'%';
      this.amadeusPresentage = amadeusvalue+'%';
      this.sabrePresentage = sabrevalue+'%';
      this.voidPresenatge = voidvalue+'%';
      this.bindDataToChart(this.dashboardData.graphData);
      this.globalSrv.theItem = this.dashboardData.unreadNotificationsCount;
      this.globalSrv.refundCountValue = this.dashboardData.userApprovalPendingRefundRequestsCount;
      this.globalSrv.reissueCountValue = this.dashboardData.userApprovalPendingReissueQuoteRequestsCount;
      this.infoBanners = this.dashboardData.infoBanners;
    },error=>{
    }))
   };

   NetValueList = [];
   GrossValueList = [];
   LabelList = [];
   
   bindDataToChart(graphData){
    graphData.forEach(element => {
      this.NetValueList.push(element.net)
      this.GrossValueList.push(element.gross)
      const format = 'yyyy-MM-ddTHH:mm:ss';
      const pipe = new DatePipe('en-US');
      let labelDate = element.label.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      var formattedDateFrom = pipe.transform(labelDate,format);
      this.LabelList.push(formattedDateFrom)
    });
    this.splineAreaChart.series[0].data = this.NetValueList;
    this.splineAreaChart.series[1].data = this.GrossValueList;
    this.splineAreaChart.xaxis.categories = this.LabelList
    this.loading = false;
    this.ChartData = true;
    this.splineAreaChart == this.splineAreaChart;
   }

   onSortSelected(val){
     if(val == '1'){
      this.selectedSortValue = 'Last 7 Days'
     }else if(val == '2'){
      this.selectedSortValue = 'Current Month'
     }else{
      this.selectedSortValue = 'Last 12 Months'
     }

    this.spinner.show();
    this.ChartData = false;
    var data= {
      'graph_option':val
    }
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.graphUpdate,data).subscribe(res => {
      this.spinner.hide();
      if(res.code == '1'){
        this.NetValueList = [];
        this.GrossValueList = [];
        this.LabelList = [];
        this.bindDataToChart(res.graphData);
      }
      // alert("success")
    },error=>{
      // alert("FAILED")
    }))
   }

   splineAreaChart: ChartType = {
    chart: {
        height: 350,
        type: 'area',
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth',
        width: 3,
    },
    series: [{
        name: 'net value of tickets issued',
        data: this.NetValueList
    }, {
        name: 'gross value of tickets issued',
        data: this.GrossValueList
    }],
    colors: ['#5b73e8', '#f1b44c'],
    xaxis: {
        type: 'datetime',
        // 2018-09-19T00:00:00
        // tslint:disable-next-line: max-line-length
        categories: this.LabelList,
    },
    grid: {
        borderColor: '#f1f1f1',
    },
    tooltip: {
        x: {
            format: 'dd/MM/yy HH:mm'
        },
    }
  };

  /**
   * Fetches the data
   */
  private async fetchData() {
    this.lineColumAreaChart = lineColumAreaChart;
    this.revenueColumnChart = revenueColumnChart;
    this.orderRadialBarChart = orderRadialBarChart;
    this.customerRadialBarChart = customerRadialBarChart;
    this.growthColumnChart = growthColumnChart;
    this.transactions = transactions;

    /**
     * Get section one data from local storage
     */
    this.section1data = JSON.parse(localStorage.getItem("section1Data"));
    if(this.section1data){
      this.isConsolidator = this.section1data.isConsolidator;
      let inqPayIdHexValue = parseInt(this.section1data.inqPayId).toString(16);
      this.payId = "T" + inqPayIdHexValue.toUpperCase();
      this.balance = parseFloat(this.section1data.inqPayBalance);
      this.globalSrv.theItem = this.section1data.unreadNotificationsCount;
      this.globalSrv.refundCountValue = this.section1data.userApprovalPendingRefundRequestsCount;
      this.globalSrv.reissueCountValue = this.section1data.userApprovalPendingReissueQuoteRequestsCount;
      this.infoBanners = this.section1data.infoBanners;

      ///Multiple account scenario
      if(this.section1data.inqbaytorPayAccounts && this.section1data.inqbaytorPayAccounts.length > 1){
        this.section1data.inqbaytorPayAccounts.forEach(element => {
          if(element.primary == 1){
            this.primaryAccountData = element;
            this.primaryBalance = parseFloat(element.closingBalance);
          }
          if(element.primary == 0){
            this.secondaryAccountData = element;
            this.secondaryBalance = parseFloat(element.closingBalance);
          }
        });
      }

    }
 

    /**
    * Get section two data from local storage
    */
    var section2Data = JSON.parse(localStorage.getItem("section2Data"));
    if(section2Data){
      // this.headerData = section2Data.header_data;
      // this.overviewData = section2Data.overview_data;
      this.overviewData = section2Data;
    }

    /**
     * Get section Three data from local storage
     */
    this.section3data = JSON.parse(localStorage.getItem("section3Data"));
    if(this.section3data){
      let galeliovalue = (this.section3data.galileoTicketsCount * 100) /  this.section3data.totalAirTicketsCount;
      let amadeusvalue = (this.section3data.amadeusTicketsCount * 100) /  this.section3data.totalAirTicketsCount;
      let sabrevalue = (this.section3data.sabreTicketsCount * 100) /  this.section3data.totalAirTicketsCount;
      let voidvalue = (this.section3data.totalVoidedTicketsCount * 100) /  this.section3data.totalAirTicketsCount;
  
      this.galelioPresentage = galeliovalue+'%';
      this.amadeusPresentage = amadeusvalue+'%';
      this.sabrePresentage = sabrevalue+'%';
      this.voidPresenatge = voidvalue+'%';
      this.bindDataToChart(this.section3data.graphData);
  
      this.topDeals = this.section3data.topDeals;
      if(this.topDeals.length > 0){
        this.arrows = true;
      }else{
        this.arrows = false;
      }
      this.topDeals.forEach((e)=>{
        var data = {
          path:e.image_url
        }
        this.images.push(data);
      });
    }
    /**
     * Get section Four data from local storage
     */
    this.transactionData = [];
    this.transactionData = JSON.parse(localStorage.getItem("section4Data"));
    if(this.transactionData){
      this.transactionData = this.transactionData.filter(x=> (x.paid_amount != "" || x.air_ticket_number != null));
    }
  
    /**
     * Get section Five data from local storage
     */
     this.section5data = [];
     this.section5data = JSON.parse(localStorage.getItem("section5Data"));
  }

  /**
   * Open modal
   * @param otpmodel modal content
   */
  openModalSMS() {
    this.spinner.show();
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.checkTime,{}).subscribe(res => {
      if(res.code == 0){
        this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.resendSms,{}).subscribe(res => {
          if(res.code == 1){
            this.spinner.hide();
            this.modalService.open(this.otpmodel,{windowClass:'modal-holder',backdrop:'static',keyboard:false});
            this.isSmsExpired = true;
            setTimeout(() => this.countdown.begin(),2000);
          }
        },error=>{
        }))
      }else{
        this.spinner.hide();
        this.modalService.open(this.otpmodel,{windowClass:'modal-holder',backdrop:'static',keyboard:false});
        this.isSmsExpired = true;
        setTimeout(() => this.countdown.begin(),1000);
      }
    },error=>{
    }))
  }

  onCloseSmsModel(){
    setTimeout(() => this.countdown.stop());
    this.modalService.dismissAll();
  }
  
  onResendVerificationCode(){
    this.isSmsExpired = true; 
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.resendSms,{}).subscribe(res => {
      this.spinner.hide();
      if(res.code == 1){
        setTimeout(() => this.countdown.begin());
      }
    },error=>{
    }))
  }

  onOtpSubmit(){
    this.smsSubmit = true;
    if(this.optForm.valid){
      this.spinner.show();
      var data = {
        "code":this.optForm.value.smsCode,
        "_mode":"verify"
      }
      this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.verifySms,data).subscribe(res => {
        this.spinner.hide();
        if(res.code == 0){
          this.toastr.error("Invalid verification code. Try again!","Error")
        }else{
          this.subscription.push(this.apiService.apiAuthGet(apiEndPoints.getUserData).subscribe(res => {
            localStorage.setItem("user",JSON.stringify(res));
            this.user.phone_verify_status = '1'
            this.onCloseSmsModel();
          },error=>{
          }))        
        }
      },error=>{
      }))
    }      
  }

  
  //** notify when timeout*/
  smsHandleEvent(event){
    if(event.action === 'done'){
      this.isSmsExpired = false;
    }
  }

  getGalelioPresentage(){
    let value = (this.dashboardData.galileoTicketCount * 100) /  this.dashboardData.totalAirTickeCount
    return value;
  }

  onAndroid(){
    window.open('https://play.google.com/store/apps/details?id=com.inq.ticketgadget&hl=en&gl=US',"_blank")
  }

  onIos(){
    window.open('https://apps.apple.com/lk/app/ticket-gadget/id1526102889',"_blank")
  }


  onTransactionOverview(){
    this.router.navigate(['reportmanagement/transaction-overview'])
  }

}
