import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  onClickOnUserGuide(){
    this.router.navigateByUrl('dashboards/user-guidelines');
  }

}
