<!-- <app-dashboard-shimmer *ngIf='loading'></app-dashboard-shimmer> -->
<!-- <ngx-spinner name="sp6"></ngx-spinner> -->
<div class="container-fluid">
    <!-- start page title -->
    <!-- <app-page-title title="Dashboard"
        category="{{section1data && !loading1? (section1data.showUserRevenueCategory == 1 ?'Revenue Category - '+ section1data.userRevenueCategory:''):''}}"
        *ngIf="loading1 || isConsolidator == 0"></app-page-title> -->
    <app-page-title title="Dashboard - 1 ({{section1data.userType}})"
        category="{{section1data && !loading1? (section1data.showUserRevenueCategory == 1 ?'Revenue Category - '+ section1data.userRevenueCategory:''):''}}"
        *ngIf="!loading1"></app-page-title>
    <!-- end page title -->

    <!--select account start-->
    <!-- <div class="row mb-3" *ngIf="section1data && !loading1">
        <div class="col-sm-2">
            <select class="form-select" placeholder="Select Currency">
                <option *ngFor="let option of section1data.inqbaytorPayAccounts" [value]="option.account_id">
                    T{{option.account_id.toString(16)}}
                    
                </option>
            </select>
        </div>
    </div> -->
    <!--select account end-->

    <ngb-alert [dismissible]="false" type="info" *ngIf="!loading1 && user.phone_verify_status != '1' ">
        Please note that unless you verify the mobile number, you will not be able to use Inqbaytor pay.
        <a (click)="openModalSMS()" class="alert-link" style="cursor: pointer;">Click here</a> to verify your mobile
        number
    </ngb-alert>
    <!-- <div id="snackbar" (click)="refreshDashboard()" [ngClass]="isShowUpdate == true ? 'snackbarShow' : 'snackbarHide'"  style="font-size: 13px;">
        A new version of the dashboard is  available.
        <a class="btn btn-primary" style="font-size: 10px;padding: 7px;">REFRESH</a>
        <i class="uil-times" style="padding-left: 5px;padding-top: 18px; font-size: 20px;cursor: pointer;"></i>
    </div> -->

    <!-- Section 1 start ----------------------------------------------------->
    <!-- <ngx-spinner name="test1" [fullScreen]="false"></ngx-spinner> -->
    <div NgxLoadingXBlur [show]="loading1">
        <div style="position: relative">
            <div canLoad (visible)="loaded(1)" throttlingTime="100" threshold=".1">
                <app-dashboard-shimmer *ngIf='(!section1data && loading1) || !section1data.inqbaytorPayAccounts'>
                </app-dashboard-shimmer>
                <ngb-alert *ngFor="let banner of infoBanners" type="{{banner.colour_class}}" [dismissible]="false">
                    {{banner.message}}
                </ngb-alert>
                <!-- <ngx-spinner [name]="spinner1" [fullScreen]="false" size="small"></ngx-spinner> -->
                <div class="row" *ngIf="(section1data || !loading1) && section1data.inqbaytorPayAccounts">
                    <div class="col-md-6 col-xl-3" *ngIf="section1data.inqbaytorPayAccounts.length == 1">
                        <div class="card">
                            <div class="card-body">
                                <div class="float-end mt-2">
                                    <i style="color: #34c38f;" class="fas fa-piggy-bank fa-2x"></i>
                                </div>
                                <div>
                                    <h5 *ngIf="balance >= 0" class="mb-1 mt-1">LKR <span
                                            data-plugin="counterup">{{+section1data.closingBalance | number
                                            :'1.2-2'}}</span></h5>
                                    <h5 *ngIf="balance < 0" class="mb-1 mt-1 text-danger">LKR <span
                                            data-plugin="counterup">{{+section1data.closingBalance | number
                                            :'1.2-2'}}</span></h5>
                                    <p class="text-muted mb-0">Balance</p>
                                </div>
                                <p class="text-muted mt-3 mb-0" style="font-size: smaller;"><span
                                        class="text-success me-1">LKR {{+section1data.currentAvailableBalance | number
                                        :'1.2-2'}}</span> available bal
                                </p>
                            </div>
                        </div>
                    </div> <!-- end col-->

                    <div class="col-md-6 col-xl-3" *ngIf="section1data.inqbaytorPayAccounts.length == 1">
                        <div class="card">
                            <div class="card-body">
                                <div class="float-end mt-2">
                                    <i style="color: #f1b44c;" class="fas fa-user fa-2x"></i>
                                </div>
                                <div>
                                    <h5 class="mb-1 mt-1"
                                        *ngIf="section1data.inqPayId != '' && section1data.inqPayId != 0">
                                        <span data-plugin="counterup">{{payId}}</span>
                                    </h5>
                                    <h5 class="mb-1 mt-1"
                                        *ngIf="section1data.inqPayId == '' || section1data.inqPayId == 0 "><span
                                            data-plugin="counterup">PENDING</span></h5>
                                    <p class="text-muted mb-0">Account Number</p>
                                </div>
                                <p class="text-muted mt-3 mb-0" style="font-size: smaller;"><span
                                        class="text-success me-1">
                                        <i class="mdi mdi-clipboard-alert-outline me-1"></i></span>use as reference for
                                    topups
                                </p>
                            </div>
                        </div>
                    </div> <!-- end col-->

                    <div class="col-md-6 col-xl-3" *ngIf="section1data.inqbaytorPayAccounts.length > 1">
                        <div class="card">
                            <div class="card-body">
                                <div class="float-end mt-2">
                                    <i style="color: #50a5f1;" class="fas fa-piggy-bank fa-2x"></i>
                                </div>
                                <div>
                                    <h5 *ngIf="primaryBalance >= 0" class="mb-1 mt-1">{{primaryAccountData.currency}}
                                        <span data-plugin="counterup">{{primaryAccountData.closingBalance}}</span></h5>
                                    <h5 *ngIf="primaryBalance < 0" class="mb-1 mt-1 text-danger">
                                        {{primaryAccountData.currency}} <span
                                            data-plugin="counterup">{{primaryAccountData.closingBalance}}</span></h5>
                                    <p class="text-muted mb-0" *ngIf="primaryAccountData">
                                        <spna style="color: #50a5f1;">{{primaryAccountData.account_type}}</spna>
                                        T{{primaryAccountData.account_id.toString(16)}}
                                        <span>
                                            <a class="mt-3 mb-0" style="margin-top: 12px;" (click)="onTransactionOverview()" href="javascript:void(0);" class="text-danger"
                                            placement="top" ngbTooltip="More">
                                            <span style="background-color: #50a5f1;" class="badge font-size-12 btn-rounded">More</span>
                                            </a>
                                        </span>
                                    </p>
                                </div>
                                <div style="display:flex;justify-content: space-between;">
                                    <p class="text-muted mt-3 mb-0" style="font-size: smaller;"><span
                                        class="text-success me-1">{{primaryAccountData.currency}}
                                        {{primaryAccountData.currentAvailableBalance}}</span> available bal
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-3" *ngIf="section1data.inqbaytorPayAccounts.length > 1">
                        <div class="card">
                            <div class="card-body">
                                <div class="float-end mt-2">
                                    <i style="color: #FFA500;" class="fas fa-piggy-bank fa-2x"></i>
                                </div>
                                <div>
                                    <h5 *ngIf="secondaryBalance >= 0" class="mb-1 mt-1">
                                        {{secondaryAccountData.currency}} <span
                                            data-plugin="counterup">{{secondaryAccountData.closingBalance}}</span></h5>
                                    <h5 *ngIf="secondaryBalance < 0" class="mb-1 mt-1 text-danger">
                                        {{secondaryAccountData.currency}} <span
                                            data-plugin="counterup">{{secondaryAccountData.closingBalance}}</span></h5>
                                    <p class="text-muted mb-0" *ngIf="secondaryAccountData"><span
                                            style="color: #FFA500;">{{secondaryAccountData.account_type}}</span>
                                        T{{secondaryAccountData.account_id.toString(16)}}
                                        <span>
                                            <a class="mt-3 mb-0" style="margin-top: 12px;" (click)="onTransactionOverview()" href="javascript:void(0);" class="text-danger"
                                            placement="top" ngbTooltip="More">
                                            <span style="background-color: #FFA500;" class="badge font-size-12 btn-rounded">More</span>
                                            </a>
                                        </span>
                                    </p>
                                </div>

                                <div style="display:flex;justify-content: space-between">
                                    <p class="text-muted mt-3 mb-0" style="font-size: smaller;"><span
                                        class="text-success me-1">{{secondaryAccountData.currency}}
                                        {{secondaryAccountData.currentAvailableBalance}}</span> available bal
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-3" *ngIf="!isAdm">
                        <div class="card">
                            <div class="card-body">
                                <div class="float-end mt-2">
                                    <i style="color: #e83e8c;" class="fas fa-coins fa-2x"></i>
                                </div>
                                <div>
                                    <h5 class="mb-1 mt-1"><span data-plugin="counterup">LKR
                                            {{+section1data.revenueForCurrentMonth | number: '1.2-2'}}</span></h5>
                                    <p class="text-muted mb-0">{{date | date:'MMM'}} Revenue</p>
                                </div>
                                <p class="text-muted mt-3 mb-0" style="font-size: smaller;"><span
                                        class="text-success me-1">LKR {{+section1data.avgDailyRevenue | number:
                                        '1.2-2'}}</span> daily avg
                                </p>
                            </div>
                        </div>
                    </div> <!-- end col-->

                    <div class="col-md-6 col-xl-3" *ngIf="isAdm">
                        <div class="card">
                            <div class="card-body">
                                <div class="float-end mt-2">
                                    <i style="color: #e83e8c;" class="fas fa-piggy-bank fa-2x"></i>
                                </div>
                                <div>
                                    <h5 *ngIf="admBalance >= 0" class="mb-1 mt-1">{{admAccountData.currency}}
                                        <span data-plugin="counterup">{{admAccountData.closingBalance}}</span></h5>
                                    <h5 *ngIf="admBalance < 0" class="mb-1 mt-1 text-danger">
                                        {{admAccountData.currency}} <span
                                            data-plugin="counterup">{{admAccountData.closingBalance}}</span></h5>
                                    <p class="text-muted mb-0" *ngIf="admAccountData"><span
                                            style="color: #e83e8c;">{{admAccountData.account_type}}</span>
                                        T{{admAccountData.account_id.toString(16)}}
                                        <span>
                                            <a class="mt-3 mb-0" style="margin-top: 12px;" (click)="onTransactionOverview()" href="javascript:void(0);" class="text-danger"
                                                placement="top" ngbTooltip="More">
                                                <span style="background-color: #e83e8c;" class="badge font-size-12 btn-rounded">More</span>
                                            </a>
                                        </span>
                                    </p>
                                </div>
                                <div style="display:flex;justify-content: space-between">
                                    <p class="text-muted mt-3 mb-0" style="font-size: smaller;"><span
                                        class="text-success me-1">{{admAccountData.currency}}
                                        {{admAccountData.currentAvailableBalance}}</span> available bal   
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="float-end mt-2">
                                    <i style="color: #D15FEE;" class="fas fa-plane fa-2x"></i>
                                </div>
                                <div>
                                    <h5 class="mb-1 mt-1">{{section1data.issuedTicketsInCurrentMonth}}</h5>
                                    <p class="text-muted mb-0">{{date | date:'MMM'}} Tickets</p>
                                </div>
                                <p class="text-muted mt-3 mb-0" style="font-size: smaller;"><span
                                        class="text-success me-1">{{section1data.voidedTicketsInCurrentMonth}}</span>
                                    {{date
                                    | date:'MMM'}} voided tickets
                                </p>
                            </div>
                        </div>
                    </div> <!-- end col-->
                </div> <!-- end row-->
            </div>
        </div>

        <!-- <ngx-loading-x [show]="!loading1"></ngx-loading-x> -->
    </div>
    <!-- Section 1 end ----------------------------------------------------->

    <!-- Section 2 start ----------------------------------------------------->
    <!-- <div class="card" canLoad (visible)="loaded(2)" throttlingTime="100" threshold=".5">
        <div class="card-body">
            <h4 class="card-title mb-4">Transactions Overview</h4>
            <ngb-alert type="info" [dismissible]="false" class="font-size-13">
                Please note that late fees will apply if the closing balance is not settled before the end of the day.
                It is recommended to do multiple payments during the day to avoid high late fees. Late fees will not be
                waived off under any circumstances. Consecutive overdue closing balances will result in the suspension
                of the overdraft facility and access to special fares.
            </ngb-alert>
            <div NgxLoadingXBlur [show]="loading2">
                <app-dashboard-shimmer *ngIf='!overviewData && loading2'></app-dashboard-shimmer>
                <div *ngIf='overviewData'>
                    <div class="mt-3" *ngFor="let mitem of overviewData.overview_data">
                        <h6 class="p-2 text-blue">{{mitem.inqbaytor_account_type}}
                            T{{mitem.inqbaytor_account_id.toString(16)}}</h6>
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th></th>
                                        <th>Currency</th>
                                        <th class="text-align-end">{{overviewData.header_data[0]}}</th>
                                        <th class="text-align-end">{{overviewData.header_data[1]}}</th>
                                        <th class="text-align-end">{{overviewData.header_data[2]}}</th>
                                        <th class="text-align-end">{{overviewData.header_data[3]}}</th>
                                        <th class="text-align-end">{{overviewData.header_data[4]}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of mitem.report_data; let i = index">
                                        <td>{{item[0]}}</td>
                                        <td>LKR</td>
                                        <td class="text-align-end"><span
                                                [ngClass]="i==0 || i == mitem.report_data.length -1 ? (item[1] >= 0 ? 'bg-soft-success badge rounded-pill font-size-12' : 'badge rounded-pill bg-soft-danger font-size-12') : ('')">{{item[1]
                                                | number : '1.2-2'}}</span> </td>
                                        <td class="text-align-end"><span
                                                [ngClass]="i==0 || i == mitem.report_data.length -1 ? (item[2] >= 0 ? 'bg-soft-success badge rounded-pill font-size-12' : 'badge rounded-pill bg-soft-danger font-size-12') : ('')">{{item[2]
                                                | number : '1.2-2'}}</span></td>
                                        <td class="text-align-end"><span
                                                [ngClass]="i==0 || i == mitem.report_data.length -1 ? (item[3] >= 0 ? 'bg-soft-success badge rounded-pill font-size-12' : 'badge rounded-pill bg-soft-danger font-size-12') : ('')">{{item[3]
                                                | number : '1.2-2'}}</span></td>
                                        <td class="text-align-end"><span
                                                [ngClass]="i==0 || i == mitem.report_data.length -1 ? (item[4] >= 0 ? 'bg-soft-success badge rounded-pill font-size-12' : 'badge rounded-pill bg-soft-danger font-size-12') : ('')">{{item[4]
                                                | number : '1.2-2'}}</span></td>
                                        <td class="text-align-end"><span
                                                [ngClass]="i==0 || i == mitem.report_data.length -1 ? (item[5] >= 0 ? 'bg-soft-success badge rounded-pill font-size-12' : 'badge rounded-pill bg-soft-danger font-size-12') : ('')">{{item[5]
                                                | number : '1.2-2'}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Section 2 end ----------------------------------------------------->

    <!-- Section 3 start ----------------------------------------------------->
    <div>
        <div class="card" *ngIf='!section3data && loading3'>
            <div class="card-body">
                <!-- <h4 class="card-title mb-4">Savings on Tickets Issued</h4> -->
                <app-dashboard-shimmer></app-dashboard-shimmer>
            </div>
        </div>
        <div NgxLoadingXBlur [show]="loading3" class="row" canLoad (visible)="loaded(3)" throttlingTime="100"
            threshold=".5">
            <!-- <div class="col-xl-8" *ngIf='section3data || !loading3'> -->
                <!-- <div class="card">
                    <div class="card-body">
                        <div class="float-end">
                            <div class="dropdown" ngbDropdown>
                                <a class="dropdown-toggle text-reset" href="javascript:void(0);"
                                    id="dropdownMenuButton5" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false" ngbDropdownToggle>
                                    <span class=" fw-semibold">Sort By:</span> <span class="text-muted">
                                        {{selectedSortValue}}<i class="mdi mdi-chevron-down ms-1"></i></span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton5"
                                    ngbDropdownMenu>
                                    <a (click)="onSortSelected('1')" class="dropdown-item"
                                        href="javascript:void(0);">Last 7 Days</a>
                                    <a (click)="onSortSelected('2')" class="dropdown-item"
                                        href="javascript:void(0);">Current Month</a>
                                    <a (click)="onSortSelected('3')" class="dropdown-item"
                                        href="javascript:void(0);">Last 12 Months</a>
                                </div>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Savings on Tickets Issued</h4>
                        <div class="mt-3">
                            <div id="sales-analytics-chart" class="apex-charts" dir="ltr">
                                <apx-chart *ngIf="ChartData" class="apex-charts" dir="ltr"
                                    [series]="splineAreaChart.series" [chart]="splineAreaChart.chart"
                                    [dataLabels]="splineAreaChart.dataLabels" [stroke]="splineAreaChart.stroke"
                                    [colors]="splineAreaChart.colors" [xaxis]="splineAreaChart.xaxis"
                                    [grid]="splineAreaChart.grid" [tooltip]="splineAreaChart.tooltip">
                                </apx-chart>
                            </div>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->
            <div class="col-xl-12" *ngIf='section3data || !loading3'>
                <carousel [objectFit]="'contain'" [cellWidth]="'100%'" [arrowsOutside]="false" [autoplay]="true"
                    [dots]="false" [loop]="true" [cellsToShow]="4" [images]="images" [arrows]="true" [borderRadius]="3">
                    <!-- <div class="card carousel-cell" *ngFor="let data of topDeals">
                        <img style="object-fit: fill;" src="{{data.image_url}}">
                    </div> -->
                    <div *ngIf="topDeals.length == 0" class="card carousel-cell" style="background-color: #4f708d;">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-sm-8">
                                    <p class="text-white font-size-18">Download our <b>Travel Agent</b> Super App </p>
                                    <div class="row mt-2">
                                        <div class="col-4">
                                            <img role="button" (click)="onAndroid()" style="height: 40px;"
                                                src="assets/images/android.png" class="img-fluid" alt=""
                                                ngbTooltip="Google Play">
                                        </div>
                                        <div class="col-4">
                                            <img role="button" (click)="onIos()" style="height: 40px;"
                                                src="assets/images/ios.png" class="img-fluid" alt=""
                                                ngbTooltip="App Store">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="mt-4 mt-sm-0">
                                        <img style="height: 157px;" src="assets/images/screenshot.png" class="img-fluid"
                                            alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </carousel>
            </div>
        </div> <!-- end row-->
        <!-- <ngx-loading-x [show]="loading3"></ngx-loading-x> -->
    </div>

    <!-- Section 4 start ----------------------------------------------------->
    <div class="row mt-4" canLoad (visible)="loaded(4)" throttlingTime="100" threshold=".2">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Latest Transactions</h4>
                    <div NgxLoadingXBlur [show]="loading4">
                        <app-table-shimmer
                            *ngIf="(transactionData != null && transactionData.length == 0 && loading4) || transactionData == null">
                        </app-table-shimmer>
                        <div class="table-responsive"
                            *ngIf='(transactionData && transactionData.length > 0) || !loading4'>
                            <table class="table table-centered table-nowrap mb-0 align-middle">
                                <thead class="table-light">
                                    <tr>
                                        <th>PNR</th>
                                        <th>Ticket Number(s)</th>
                                        <th>Transaction Type</th>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                        <th>Account</th>
                                        <th>Username</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of transactionData">
                                        <td><span
                                                *ngIf="data.paid_amount != '' || data.air_ticket_number == null">{{data.pnr}}</span>
                                        </td>
                                        <td>{{data.air_ticket_number}}</td>
                                        <td>
                                            <span *ngIf="data.paid_amount != '' || data.air_ticket_number == null"
                                                class="badge rounded-pill bg-soft-success font-size-12" [ngClass]=" { 'bg-soft-success': data.transaction_type_value == '1',
                                            'bg-soft-warning':data.transaction_type_value != '1' }">
                                                {{data.transaction_type}}</span>
                                        </td>
                                        <td><span *ngIf="data.paid_amount != '' || data.air_ticket_number == null"
                                                style="float: left;">{{data.currency}}</span></td>
                                        <td>
                                            <span
                                                *ngIf="data.paid_amount != '' && (data.transaction_type_value*data.paid_amount) > 0 "><span
                                                    style="float: right;">+{{data.paid_amount | number :
                                                    '1.2-2'}}</span></span>
                                            <span
                                                *ngIf="data.paid_amount != '' && (data.transaction_type_value*data.paid_amount) < 0 "><span
                                                    style="float: right;">-{{data.paid_amount | number :
                                                    '1.2-2'}}</span></span>
                                        </td>
                                        <td>
                                            <span *ngIf="data.inqbaytor_pay_id" class="cursorPointer" placement="top"
                                                ngbTooltip="T{{data.inqbaytor_pay_id.toString(16)}}">
                                                {{data.account_type}}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="data.username">
                                                {{data.username}}
                                            </span>
                                        </td>
                                        <td><span
                                                *ngIf="data.paid_amount != '' || data.air_ticket_number == null">{{data.created_at
                                                | date: 'dd-MM-yyyy'}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <ngx-loading-x [show]="loading4"></ngx-loading-x> -->
                    </div>
                    <!-- end table -->
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row" canLoad (visible)="loaded(2)" throttlingTime="100" threshold=".5">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Latest Transactions</h4>
                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap mb-0 align-middle">
                                <thead class="table-light">
                                    <tr>
                                        <th>PNR</th>
                                        <th>Ticket Number(s)</th>
                                       <th>Transaction Type</th>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                        <th>Date</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of transactionData">
                                        <td><span
                                                *ngIf="data.paid_amount != '' || data.air_ticket_number == null">{{data.pnr}}</span>
                                        </td>
                                        <td>{{data.air_ticket_number}}</td>
                                        <td>
                                            <span *ngIf="data.paid_amount != '' || data.air_ticket_number == null"
                                                class="badge rounded-pill bg-soft-success font-size-12" [ngClass]=" { 'bg-soft-success': data.transaction_type_value == '1',
                                            'bg-soft-warning':data.transaction_type_value != '1' }">
                                                {{data.transaction_type}}</span>
                                        </td>
                                        <td><span *ngIf="data.paid_amount != '' || data.air_ticket_number == null"
                                                style="float: left;">{{data.currency}}</span></td>
                                        <td>
                                            <span
                                                *ngIf="data.paid_amount != '' && (data.transaction_type_value*data.paid_amount) > 0 "><span
                                                    style="float: right;">+{{data.paid_amount | number :
                                                    '1.2-2'}}</span></span>
                                            <span
                                                *ngIf="data.paid_amount != '' && (data.transaction_type_value*data.paid_amount) < 0 "><span
                                                    style="float: right;">-{{data.paid_amount | number :
                                                    '1.2-2'}}</span></span>
                                        </td>
                                        <td><span
                                                *ngIf="data.paid_amount != '' || data.air_ticket_number == null">{{data.created_at
                                                | date: 'dd-MM-yyyy'}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Section 4 end ----------------------------------------------------->

    <!-- Section 5 start ----------------------------------------------------->
    <div class="row" canLoad (visible)="loaded(5)" throttlingTime="100" threshold=".5">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Latest Tickets</h4>
                    <div NgxLoadingXBlur [show]="loading5">
                        <app-table-shimmer
                            *ngIf='(section5data != null && section5data.length == 0 && loading5) || section5data == null'>
                        </app-table-shimmer>
                        <div class="table-responsive" *ngIf='(section5data && section5data.length > 0) || !loading5'>
                            <table class="table table-centered table-nowrap mb-0 align-middle">
                                <thead class="table-light">
                                    <tr>
                                        <th>Airline</th>
                                        <th>Ticket Number</th>
                                        <th>Passenger Name</th>
                                        <th>PNR</th>
                                        <th>Activity Time</th>
                                        <th>Issued Date</th>
                                        <th>Account</th>
                                        <th>Username</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of section5data">
                                        <td>
                                            <div *ngIf="data.air_line_code != ''">
                                                <img style="height: 38px;width: 38px;"
                                                    src="https://crazyjets.com/assets/images/airline-logos/{{data.air_line_code}}.jpg"
                                                    alt="{{data.air_line_code}}"
                                                    class="avatar-sm rounded-circle img-thumbnail"
                                                    ngbTooltip="{{data.air_line_code}}"
                                                    onerror="this.style.display='none'" />
                                            </div>
                                        </td>
                                        <td>{{data.ticket_number}}</td>
                                        <td>{{data.passenger_name | uppercase}}</td>
                                        <td>{{data.pnr}}</td>
                                        <td>{{data.activity_time | date: 'dd-MM-yyyy'}}</td>
                                        <td>{{data.ticket_issued_date}}</td>
                                        <td>
                                            <span *ngIf="data.inqbaytor_pay_id" class="cursorPointer" placement="top"
                                                ngbTooltip="T{{data.inqbaytor_pay_id.toString(16)}}">
                                                {{data.account_type}}
                                            </span>
                                        </td>
                                        <span *ngIf="data.username">
                                            {{data.username}}
                                        </span>
                                        <td>
                                            <span class="badge rounded-pill bg-soft-success font-size-12" [ngClass]=" { 'bg-soft-success': data.status === 1,
                                              'bg-soft-warning':data.status === 2 }">{{data.status == 1 ?
                                                'Issued':'Voided'}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <ngx-loading-x [show]="loading5"></ngx-loading-x> -->
                    </div>
                    <!-- end table -->
                </div>
            </div>
        </div>
    </div>
    <!-- Section 5 end ----------------------------------------------------->
</div> <!-- container-fluid -->

<!-- sms Modal -->
<ng-template #otpmodel role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Enter the 6-digit code</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="onCloseSmsModel()"></button>
    </div>
    <form (ngSubmit)="onOtpSubmit()" [formGroup]="optForm">
        <div class="modal-body">
            <p>Please enter the 6-digit code which was sent to {{user.mobile_number}} to verify your authenticity.</p>
            <div class="col-12 mb-3">
                <label for="horizontal-firstname-input" class="col-sm-6 col-form-label">Code<span class="r-star">
                        *</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="smsCode"
                        [ngClass]="{'is-invalid': smsSubmit && smstype.smsCode.errors}" placeholder="123456" />
                    <div *ngIf="smsSubmit && smstype.smsCode.errors" class="invalid-feedback">
                        <span *ngIf="smstype.smsCode.errors.required">This value is
                            required.</span>
                    </div>
                </div>
            </div>
            <p *ngIf="isSmsExpired" class="font-size-13">Resend agian in <span class="text-orange">
                    <countdown #otp [config]="smstimeOutConfig" (event)="smsHandleEvent($event)"></countdown>
                </span></p>
            <a *ngIf="!isSmsExpired" class="text-orange" href="javascript:void(0);"
                (click)="onResendVerificationCode()">Resend verification code</a>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="onCloseSmsModel()">Close</button>
            <button type="submit" class="btn btn-primary">Continue</button>
        </div>
    </form>
</ng-template>