import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 6,
        label:'Dashboard',
        icon: 'uil-home-alt',
        // badge: {
        //     variant: 'primary',
        //     text: '01', //'MENUITEMS.DASHBOARDS.BADGE',
        // },
        link:'/dashboard',
    },
    {
        id: 7,
        label: 'Flight Tickets',
        icon: 'uil-ticket',
        subItems: [
            {
                id: 8,
                label: 'Issue Flight Ticket',
                link: '/ticketmanagement/issue-ticket',
                parentId: 7
            },
            {
                id: 9,
                label: 'Flight Ticket List',
                link: '/ticketmanagement/issue-ticket-list',
                parentId: 7
            },
            // {
            //     id: 10,
            //     label: 'Queued Flight Bookings',
            //     link: '/ticketmanagement/queue-booking-list',
            //     parentId: 7
            // },
            {
                id: 11,
                label: 'Ticket Documents',
                link: '/ticketmanagement/ticket-documents',
                parentId: 7
            },
        ]
    },
    {
        id: 12,
        label: 'Flight Bookings',
        icon: 'uil-search',
        subItems: [
            {
                id: 13,
                label: 'Flight Search',
                link: '/flightmanagement/flight-search',
                parentId: 11
            },
            {
                id: 14,
                label: 'Advanced Flight Search',
                link: '/flightmanagement/advance-flight-search',
                parentId: 11
            },
            {
                id: 15,
                label: 'Flight Booking List',
                link: '/flightmanagement/flight-booking-list',
                parentId: 11
            },
        ]
    },
    {
        id: 16,
        label: 'Reports',
        icon: 'uil-document-info',
        subItems: [
            {
                id: 17,
                label: 'Transactions',
                link: '/reportmanagement/transaction',
                parentId: 15
            },
            {
                id: 18,
                label: 'Statements',
                link: '/reportmanagement/statement',
                parentId: 15
            },
            {
                id: 1018,
                label: 'Transaction Overview',
                link: '/reportmanagement/transaction-overview',
                parentId: 15
            },
        ]
    },
    {
        id: 19,
        label: 'Top Up',
        icon: 'uil-money-bill',
        link: '/topup',
    },
    {
        id: 39,
        label: 'ADM',
        icon: 'uil-usd-square',
        link:'/adm',
        badge:true
    },    
    // {
    //     id: 19,
    //     label: 'MENUITEMS.CREDITCARD.TEXT',
    //     icon: 'uil-card-atm',
    //     link: '/creditcard-payment',
    // },
    // {
    //     id: 19,
    //     label:'MENUITEMS.DASHBOARDS.TEXT',
    //     icon: 'uil-home-alt',
    //     // badge: {
    //     //     variant: 'primary',
    //     //     text: '01', //'MENUITEMS.DASHBOARDS.BADGE',
    //     // },
    //     link:'/dashboard',
    // },
    // {
    //     id: 19,
    //     label: 'MENUITEMS.CREDITCARD.TEXT',
    //     icon: 'uil-card-atm',
    //     link: '/creditcard-payment',
    // },
    // {
    //     id: 20,
    //     label:'MENUITEMS.OVERDRAFT.TEXT',
    //     icon: 'uil-0-plus',
    //     link:'/overdraft',
    // },
    {
        id: 20,
        label: 'Card Payments',
        icon: 'uil-card-atm',
        link: '/card-payment',
    },
    {
        id: 21,
        label:'Overdraft',
        icon: 'uil-0-plus',
        link:'/overdraft',
    },
    {
        id: 22,
        label: 'Refunds',
        icon: 'uil-dollar-alt',
        badge:true,
        subItems: [
            {
                id: 23,
                label: 'All Refunds',
                link: "/refund-requests",
                param:'all',
                parentId: 22
            },
            {
                id: 24,
                label: 'Open',
                link: '/refund-requests',
                param:'open',
                parentId: 22
            },
            {
                id: 25,
                label: 'Awaiting User Approval',
                link: '/refund-requests',
                param:'awaiting-user-approval',
                parentId: 22
            },
            {
                id: 26,
                label: 'Approved',
                link: '/refund-requests',
                param:"approved",
                parentId: 22
            },
            {
                id: 27,
                label: 'Awaiting Airline Settlement',
                link: '/refund-requests',
                param:'awaiting-airline-settlement',
                parentId: 22
            },
            {
                id: 28,
                label: 'On-hold',
                link: '/refund-requests',
                param:'on-hold',
                parentId: 22
            },
            {
                id: 29,
                label: 'Resolved',
                link: '/refund-requests',
                param:'resolved',
                parentId: 22
            },
            {
                id: 30,
                label: 'Declined',
                link: '/refund-requests',
                param:'declined',
                parentId: 22
            },          
            {
                id: 31,
                label: 'Expired',
                link: '/refund-requests',
                param:'expired',
                parentId: 22
            },     
        ]
    },
    {
        id: 32,
        label: 'Fees',
        icon: 'uil-clipboard-notes',
        link:'/fees'
    },
    {
        id: 33,
        label: 'Announcements',
        icon: 'uil-notes',
        link:'/announcement'
    },
    {
        id: 34,
        label: 'Reissue Quotation',
        icon: 'uil-file-contract-dollar',
        subItems: [
            {
                id: 35,
                label: 'Reissue Quotation',
                link: '/reissue-quote',
                parentId: 34
            },
            {
                id: 36,
                label: 'Reissue Quotation List',
                link: '/reissue-requests',
                parentId: 34
            },            
        ]
    },    
    {
        id: 38,
        label: 'Reissue Quotation',
        icon: 'uil-file-contract-dollar',
        link:'/reissue-quotation'
    },
    {
        id: 37,
        badge:true,
        label: 'FAQs',
        icon: 'uil-cloud-question',
        link:'/faq'
    },
];

