import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { redirectUrls } from './redirect-url';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private toastr: ToastrService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                var value = error.url.includes('cjwapi.inqbaytor');
                var isEventUrl = error.url.includes('events.inqbaytor');
                if(!isEventUrl){
                    if(!value){
                        if (error.status === 401) {
                            this.toastr.error("unauthorized!","Error")
                            // auto logout if 401 response returned from api
                            sessionStorage.clear();
                            localStorage.clear();
                            const url = redirectUrls.redirectLogin;
                            window.location.href = url;
                        }
                        if (error.status === 404) {
                            this.toastr.error("Not Found, Please try again later !","Error")
                        }  
                    }else{
                        if (error.status === 404) {
                            this.toastr.error("Not Found, Please try again later !","Error")
                            localStorage.removeItem('access-token1');
                            const url = redirectUrls.redirectDashboard
                            window.location.href = url;
                        }                
                    }
                    if (error.status === 500) {
                        this.toastr.error("Internal Server Error")
                        // alert("500 error")
                        // this.router.navigateByUrl('dashboard/500');
                        // this.router.navigate(['/pages/500']);
                    }
                    if(error.status === 0){
                        this.toastr.error("Seems like there's a connection problem.")
                    }
                    const err = error.error.message || error.statusText;
                    return throwError(err);  
                }
            }),
            map(event => {
                if (event instanceof HttpResponse) {
                    let dateTime = new Date();
                    // if(!event.url.includes('events.inqbaytor')){
                    localStorage.setItem('latestApiDateTime',dateTime.toUTCString());
                    // }
                } else {

                }
                return event;
            }));
        // return next.handle(request).pipe(
        //     catchError(err => {
        //         this.spinner.hide()
        //         // this.router.navigate(['/pages/500']);
        //         var value = err.url.includes('cjwapi.inqbaytor')
        //         if(!value){
        //             if (err.status === 401) {
        //                 this.toastr.error("unauthorized!","Error")
        //                 // auto logout if 401 response returned from api
        //                 sessionStorage.clear();
        //                 localStorage.clear();
        //                 const url = redirectUrls.redirectLogin;
        //                 window.location.href = url;
        //             }
        //         }else{
        //             if (err.status === 404) {
        //                 this.toastr.error("Not Found, Please try again later !","Error")
        //                 localStorage.removeItem('access-token1');
        //                 const url = redirectUrls.redirectDashboard
        //                 window.location.href = url;
        //             }                
        //         }
        //         if (err.status === 500 || err.status === 0) {
        //             this.toastr.error("Internal Server Error")
        //             // alert("500 error")
        //             // this.router.navigateByUrl('dashboard/500');
        //             // this.router.navigate(['/pages/500']);
        //         }

        //         const error = err.error.message || err.statusText;
        //         return throwError(error);
        //     }
        // ));
    }
}
