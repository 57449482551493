import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {

  @Input() breadcrumbItems;
  @Input() title: string;
  @Input() category: string;

  constructor(
    private router:Router
  ) { }

  ngOnInit() {
  }

  onClick(val){
    this.router.navigate([val]);
  }

}
