import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';
import { DateAgoPipe } from '../pages/dashboards/notifications/date-ago.pipe';

@NgModule({
  declarations: [
    DateAgoPipe
  ],
  exports:[
    DateAgoPipe
  ],
  imports: [
    CommonModule,
    UIModule
  ],
})

export class SharedModule { }
