import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { apiEndPoints } from '../core/helpers/api-endpoints';
import { redirectUrls } from '../core/helpers/redirect-url';
import { ApiService } from '../core/services/api.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private apiService:ApiService,
    private spinner: NgxSpinnerService,
    private route:ActivatedRoute,
  ) { }

  subscription:Subscription[] = [];


  ngOnInit(): void {
      var fcmToken = localStorage.getItem('fcm');
      this.spinner.show();
      if(fcmToken != null){
        try {
          // this.afMessaging.getToken
          // .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
          // .subscribe(
          //   (token) => { 
              // console.log('Token deleted!'+token); 
              var data = {
                "firebase_token":fcmToken
              }
              this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.fcmDelete,data).subscribe(res => {
                if(res['code'] == 1){
                  sessionStorage.clear();
                  localStorage.clear();
                  this.spinner.hide();
                  const url = redirectUrls.redirectLogin;
                  window.location.href = url;             
                }
              },error=>{
              }))              
        } catch (error) {
                var data = {
                  "firebase_token":fcmToken
                }
                this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.fcmDelete,data).subscribe(res => {
                  this.spinner.hide();
                  if(res['code'] == 1){
                    sessionStorage.clear();
                    localStorage.clear();
                    this.spinner.hide();
                    const url = redirectUrls.redirectLogin;
                    window.location.href = url;             
                  }
                },error=>{
                  // alert("FAILED")
                }))              
            //   },
            // );
          // }
        }
      }else{
        sessionStorage.clear();
        localStorage.clear();
        this.spinner.hide();
        const url = redirectUrls.redirectLogin;
        window.location.href = url; 
      }
    }    
  }
